import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import { Tween, ScrollTrigger, Timeline, PlayState } from 'react-gsap'
import { useVideoForViewport, useWindowDimensions } from '../../utils/hooks'
import { colors, breakpoints } from '../../../config/design-system'
import { toRem, mediaQuery } from '../../styles'
import showReelVideoSm from '../../assets/home/showreel-mobile.mp4'
import showReelVideoLg from '../../assets/home/showreel-desktop.mp4'

const StyledOverlayLayer = styled.span`
  ${tw`w-3/6 absolute h-full top-0 bg-dark-bg-color z-20`};
  transition: all 0.35s ease;
`

const titleStyles = css`
  ${tw`absolute uppercase font-heading font-black text-transparent z-30 pointer-events-none`};
  -webkit-text-stroke: 1px ${colors['light-text-color']};
  font-size: ${toRem(178)};
  left: calc(100vw * 0.14);
  top: 50%;
  transform: translateY(-50%);
  ${mediaQuery(`font-size: ${toRem(218)};`, 'desktop')}
`

const subTitleStyles = css`
  ${tw`flex uppercase font-heading font-black text-transparent`};
  -webkit-text-stroke: 1px ${colors['light-text-color']};
  font-size: ${toRem(66)};
`

const videoStyles = css`
  ${tw`absolute z-10`};
  width: auto;
  height: 100%;
  min-width: 100%;
  max-width: unset;
  left: 50%;
  transform: translateX(-50%);
  cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4NiIgaGVpZ2h0PSI4NiIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iNDMiIGZpbGw9IiNFNTU3MDgiLz48cGF0aCBmaWxsPSIjRjFGMUYxIiBkPSJtMTkuNjY3MyA1MCAzLjE4LTEyLjQuMDgtLjc4aC4yMmwuMDYuNzggMy4xOCAxMi40aDEuNjJsMy44NC0xNGgtMS4xNmwtMy4zMiAxMi4zNi0uMDYuODhoLS4yNGwtLjA0LS44OC0zLjE2LTEyLjM2aC0xLjY4bC0zLjE2IDEyLjM2LS4wNC44OGgtLjI0bC0uMDQtLjg4LTMuMzQtMTIuMzZoLTEuMTRsMy44NCAxNGgxLjZabTE2Ljk5MTEtOS41OGMtMy45IDAtMy45MiAzLjA0LTMuOTIgMy4zNnYuMDZoMS4wNHYtLjA2YzAtLjI2LjAyLTIuNDIgMi44Ni0yLjQyIDEuODYgMCAyLjcyLjk0IDIuNzIgMi42MnYuNzJsLTMuMjQuMmMtMS4xLjA4LTEuOTYuMzgtMi41LjkyLS40Ni40Ni0uNjggMS4wNC0uNjggMS44cy4yNCAxLjM4LjcgMS44NGMuNS41IDEuMjYuOCAyLjE2LjggMS44NiAwIDMuMTItMS4wNiAzLjYyLTMuMDZoLjIybC0uMzQgMi44aDEuMDh2LTYuMDJjMC0yLjMyLTEuMjYtMy41Ni0zLjcyLTMuNTZabS0uNjggOC45MmMtLjY2IDAtMS4xOC0uMi0xLjU0LS41NC0uMjgtLjMtLjQ0LS42OC0uNDQtMS4xOCAwLS40OC4xNC0uODYuNDItMS4xNi4zOC0uMzYuOTgtLjU4IDEuODYtLjY2bDMuMDgtLjI2di4yOGMwIDEuNDQtMS4wMiAzLjUyLTMuMzggMy41MlptMTQuMDU4Mi04LjY2aC00LjU0di0yLjc2bC0xLjAyLjUydjIuMjRoLTIuMTR2Ljk0aDIuMTR2NS41OGMwIDEuOTYuOTQgMy4wNiAyLjc2IDMuMDYgMS43NiAwIDIuOC0xLjE4IDIuOC0zLjI0di0xLjdoLTEuMDJ2MS42NmMwIDEuNTYtLjYgMi4zNC0xLjc4IDIuMzQtMS4xNCAwLTEuNzQtLjgtMS43NC0yLjEydi01LjU4aDQuNTR2LS45NFptNi42MjI1IDkuNThjMi4zOCAwIDQuMDQtMS40MiA0LjItMy42aC0xLjAyYy0uMiAxLjY2LTEuMzggMi42Ni0zLjE0IDIuNjYtMS4yIDAtMi4xNi0uNS0yLjc2LTEuMzYtLjQ2LS42Ni0uNy0xLjU0LS43LTIuNiAwLTEuMDguMjQtMS45Ni43Mi0yLjYyLjYyLS45IDEuNTgtMS4zOCAyLjgyLTEuMzggMS44MiAwIDMuMDYgMS4wMiAzLjI2IDIuNjhoMS4wMmMtLjE0LTIuMTYtMS44Ni0zLjYyLTQuMjgtMy42Mi0xLjY0IDAtMi45NC42Ni0zLjcyIDEuOC0uNTguODItLjg4IDEuODgtLjg4IDMuMTQgMCAxLjI2LjMgMi4zNC44NiAzLjE0Ljc4IDEuMTIgMi4wNCAxLjc2IDMuNjIgMS43NlpNNjUuMDE4IDUwdi00LjcyYzAtMi4zIDEuMjYtMy45IDMuMy0zLjkgMS42MiAwIDIuNS45OCAyLjUgMi45NFY1MGgxLjA0di01LjljMC0yLjM0LTEuMi0zLjY4LTMuMzgtMy42OC0xLjc0IDAtMy4wOC45Ni0zLjU0IDMuMDRoLS4yNGwuMzItMi43OFYzNmgtMS4wMnYxNGgxLjAyWiIvPjwvc3ZnPg=='),
    auto;

  @media (min-aspect-ratio: 16/9) {
    height: auto;
    width: 100%;
  }

  @media (max-aspect-ratio: 16/9) {
    height: 100%;
    width: auto;
  }
`

const Showreel = ({ id, fullScreenShowReel, handleOpenModal }) => {
  const titleWidth = 1470 // showreel title width
  const videoSrc = useVideoForViewport(showReelVideoSm, showReelVideoLg)
  const { viewportHeight, viewportWidth } = useWindowDimensions()
  const [animationProgress, setAnimationProgress] = useState(0)
  const videoRef = useRef()
  const playState = viewportWidth > breakpoints['tablet-landscape'] && PlayState.pause

  useEffect(() => {
    const { current: video } = videoRef

    if (animationProgress > 0) {
      video.play()
    } else {
      video.pause()
    }
  }, [animationProgress])

  return (
    <section tw="w-full h-screen relative opacity-100 visible overflow-hidden" id={id}>
      <ScrollTrigger
        pin
        trigger={`#${id}`}
        start="top top"
        end={() => `+=${viewportHeight * 0.9}`}
        onUpdate={({ progress }) => {
          setAnimationProgress(progress)
        }}
        scrub
      >
        <Timeline>
          <Tween to={{ x: '-100%' }}>
            <StyledOverlayLayer tw="left-0" />
          </Tween>
          <Tween to={{ x: '100%' }} position="-=1">
            <StyledOverlayLayer tw="right-0" />
          </Tween>
          <Tween to={{ x: -(viewportWidth * 0.5 + titleWidth) }} position="-=1">
            <strong css={titleStyles}>Showreel</strong>
          </Tween>
          <Tween
            to={{
              minHeight: 'unset',
              height: 'auto',
              width: '100%',
              position: 'static',
              transform: 'translate(0%, 25%)'
            }}
            position="showreel"
            playState={playState}
          >
            <video
              ref={videoRef}
              css={[videoStyles]}
              loop
              autoPlay
              playsInline
              muted
              onClick={handleOpenModal}
              tabIndex={0}
              role="button"
              aria-label="Open"
            >
              <source type="video/mp4" src={videoSrc} />
            </video>
          </Tween>
          <div tw="flex flex-col gap-4 mt-6 ml-4 absolute md:hidden" css={{ bottom: '10%' }}>
            <div tw="relative leading-none overflow-hidden">
              <Tween
                from={{ y: '100%' }}
                to={{ y: 0 }}
                position="showreel+=0.25"
                playState={playState}
              >
                <strong css={subTitleStyles}>Show</strong>
              </Tween>
            </div>
            <div tw="relative leading-none overflow-hidden">
              <Tween
                from={{ y: '100%' }}
                to={{ y: 0 }}
                position="showreel+=0.25"
                playState={playState}
              >
                <strong css={subTitleStyles}>reel</strong>
              </Tween>
            </div>
          </div>
        </Timeline>
      </ScrollTrigger>
    </section>
  )
}

Showreel.PropType = {
  id: PropTypes.string.isRequired,
  fullScreenShowReel: PropTypes.bool.isRequired,
  setFullScreenShowReel: PropTypes.func.isRequired
}

export { Showreel }
