import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../config/design-system'
import { toRem } from '../styles'
import tw, { css, styled } from 'twin.macro'
import { useScrollBlock } from '../utils/hooks'

const StyledModal = styled.div`
  ${tw`w-screen h-screen fixed top-0 left-0 z-50 p-12`};
  ${({ show }) =>
    show
      ? tw`opacity-100 visible pointer-events-auto`
      : tw`opacity-0 invisible pointer-events-none`}
  background-color: rgba(0,0,0,0.8);
  transition: opacity 0.2s;
`

const closeBtnStyles = css`
  ${tw`flex items-center gap-3 absolute right-0 z-50 font-subheading font-bold`}
  top: -${toRem(40)};
`

const svgStyles = css`
  width: ${toRem(20)};
  height: ${toRem(20)};
  path {
    stroke: ${colors['light-bg-color']};
  }
`

const modalContentStyles = css`
  ${tw`w-full h-full absolute`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-aspect-ratio: 16/9) {
    height: 80vh;
    width: max-content;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 87vw;
    height: auto;
  }
`

const Modal = ({ closeModal, show = false, children }) => {
  const [blockScroll, allowScroll] = useScrollBlock()
  useEffect(() => {
    if (show) {
      blockScroll()
      return
    }
    allowScroll()
  }, [show, allowScroll, blockScroll])

  return (
    <StyledModal show={show}>
      <div css={modalContentStyles}>
        <button css={closeBtnStyles} onClick={closeModal} type="button">
          <span>Close</span>
          <svg css={svgStyles} viewBox="0 0 20 20" fill="none">
            <path d="M1.5 18.5L18 2" strokeWidth="3" />
            <path d="M1.5 2L18 18.5" strokeWidth="3" />
          </svg>
        </button>
        {children}
      </div>
    </StyledModal>
  )
}

Modal.PropType = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
