import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import tw, { css } from 'twin.macro'
import { Tween, ScrollTrigger, Timeline, PlayState } from 'react-gsap'
import { breakpoints } from '../../../config/design-system'
import { toRem, mediaQuery, StyledSection, h1Styles, h2Styles, btnStyles } from '../../styles'
import { buttonStyles } from '../Button'
import careersQuoteImage from '../../assets/home/careers_quote.jpg'

const imageHeight = 253

const wrapStyles = css`
  ${tw`flex flex-col justify-between h-full`};
  max-height: 88%;
  ${mediaQuery(`
    max-height: 75%;
  `)}
  ${mediaQuery(
    `
    max-height: 42.5rem;
  `,
    'big-desktop'
  )}
`
const quoteContainerStyles = css`
  ${tw`relative flex flex-col`};
  height: ${toRem(imageHeight)};
`

const imageStyles = css`
  ${tw`absolute top-0 self-end pointer-events-none`};
  width: 80%;
  left: 50%;
  top: 50%;
  transform: rotate(15deg) translate(-50%, -50%);
  ${mediaQuery(
    `
    width: auto;
    height: ${toRem(imageHeight)};
  `,
    'phone'
  )}
  ${mediaQuery(`
    transform: rotate(15deg) translate(0, -50%);
  `)}
`

const blockQuoteStyles = css`
  ${tw`flex flex-col items-end w-full absolute`};
  top: 50%;
  transform: translateY(-50%);
`

const underlineStyles = css`
  ${tw`absolute`};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const quoteUnderlineStyles = css`
  width: ${toRem(99)};
  height: ${toRem(30)};
`

const ctaUnderlineStyles = css`
  width: ${toRem(95)};
  height: ${toRem(35)};
  ${mediaQuery(
    `
    width: ${toRem(127)};
    height: ${toRem(47)};
  `,
    'laptop'
  )}
`

const SlideUpComponent = ({ children, position }) => {
  const windowExists = typeof window !== 'undefined'

  const playState = useMemo(() => {
    const mq = windowExists && window.matchMedia(`(min-width: ${breakpoints.desktop}px)`)
    return mq.matches ? PlayState.play : PlayState.stopEnd
  }, [windowExists])

  return (
    <div tw="overflow-hidden">
      <Tween
        from={{ y: '100%' }}
        to={{ y: 0 }}
        ease="Power3.easeIn"
        duration={0.75}
        position={position}
        playState={playState}
      >
        {children}
      </Tween>
    </div>
  )
}

const Careers = ({ id }) => {
  return (
    <StyledSection tw="flex flex-col justify-center h-screen" id={id}>
      <div css={wrapStyles}>
        <div css={quoteContainerStyles}>
          <img css={imageStyles} src={careersQuoteImage} alt="Orson Welles smoking" />
          <ScrollTrigger trigger="#blockquote">
            <blockquote css={blockQuoteStyles} id="blockquote">
              <Timeline>
                <SlideUpComponent>
                  <span css={[h2Styles, tw`flex self-start font-thin`]}>
                    "The strength of the team comes from each member.
                  </span>
                </SlideUpComponent>
                <SlideUpComponent position="-=0.6">
                  <strong css={[h2Styles, tw`inline xl:inline-flex`]}>
                    The strength of each member is the
                    <span tw="inline-flex relative ml-3 lg:ml-2">
                      <span>team</span>
                      <svg
                        css={[underlineStyles, quoteUnderlineStyles]}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.43 12.993C29.542-1.51 100.347-3.56 97.977 11.356 96.199 28.06 3.164 35.19 1.478 21.556-.339 6.855 40.758.053 75.992 5.645"
                          stroke="#E55708"
                        />
                      </svg>
                    </span>
                    "
                  </strong>
                </SlideUpComponent>
                <SlideUpComponent position="-=0.6">
                  <cite tw="flex text-tag-sm font-thin not-italic lg:text-tag-lg">
                    Orson Welles
                  </cite>
                </SlideUpComponent>
              </Timeline>
            </blockquote>
          </ScrollTrigger>
        </div>
        <div tw="self-end">
          <ScrollTrigger trigger="#careers-p">
            <Timeline>
              <div id="careers-p">
                <SlideUpComponent>
                  <span css={[h1Styles, tw`flex font-thin`]}>Don't be shy.</span>
                </SlideUpComponent>
                <strong
                  css={[h1Styles, tw`inline-flex flex-col leading-tight`]}
                  id="careers-strong"
                >
                  <SlideUpComponent position="-=0.6">
                    <span tw="inline xl:inline-flex">
                      <div tw="inline-flex relative ml-2 mr-3">
                        <span>Join</span>
                        <svg
                          css={[underlineStyles, ctaUnderlineStyles]}
                          viewBox="0 0 127 46"
                          fill="none"
                        >
                          <path
                            d="M116.265 17.5857C102.359 -5.43285 8.81472 -4.16948 1.30053 20.5814C-8.28852 48.3541 111.443 54.1009 123.48 31.2246C136.459 6.5569 89.4582 -5.08985 38.1693 6.57947"
                            stroke="#E55708"
                          />
                        </svg>
                      </div>
                      our talented team and
                    </span>
                  </SlideUpComponent>
                  <SlideUpComponent position="-=0.6">
                    <span tw="flex">create the future of entertainment</span>
                  </SlideUpComponent>
                </strong>
              </div>
              <SlideUpComponent position="-=0.6">
                <div tw="flex items-center mt-5 gap-3 md:justify-end">
                  <span css={btnStyles}>See our open positions</span>
                  <Link to="/careers">
                    <span css={buttonStyles} tw="flex">
                      →
                    </span>
                  </Link>
                </div>
              </SlideUpComponent>
            </Timeline>
          </ScrollTrigger>
        </div>
      </div>
    </StyledSection>
  )
}

Careers.PropType = {
  id: PropTypes.string.isRequired
}

export { Careers }
