import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'

const buttonStyles = css`
  ${tw`flex justify-center items-center w-10 h-10 border rounded-full text-button-lg`};
  transition: all 0.15s;
  &:hover {
    ${tw`bg-light-bg-color text-dark-text-color`};
  }
  &:disabled {
    ${tw`cursor-auto pointer-events-none`};
    opacity: 0.35;
  }
`

const Button = ({ onClick, action }) => {
  const btnText = {
    default: '→',
    upload: '↑',
    add: '+'
  }

  return (
    <button css={buttonStyles} type="button" onClick={onClick}>
      {btnText[action] || btnText.default}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  action: PropTypes.string
}

export { Button, buttonStyles }
