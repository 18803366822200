import React, { useState, useRef } from 'react'
import { css } from 'twin.macro'
import { Tween, ScrollTrigger } from 'react-gsap'
import { useVideoForViewport, useOutsideClick } from '../utils/hooks'
import Layout from '../components/Layout'
import Header from '../components/Header'
import AnchorMenu from '../components/AnchorMenu'
import Footer from '../components/Footer'
import { Hero, Showreel, Careers } from '../components/home'
import Menu from '../components/Menu'
import Modal from '../components/modal'
import showReelVideoSm from '../assets/home/showreel-mobile.mp4'
import showReelVideoLg from '../assets/home/showreel-desktop.mp4'

const videoStyles = css`
  height: calc(100% - 4px);
  border: 2px solid white;

  @media (min-aspect-ratio: 16/9) {
    height: calc(100% - 4px);
    width: auto;
  }

  @media (max-aspect-ratio: 16/9) {
    width: calc(100% - 4px);
    height: auto;
  }
`

const IndexPage = ({ location }) => {
  const videoSrc = useVideoForViewport(showReelVideoSm, showReelVideoLg)
  const videoRef = useRef(null)

  const sections = [
    {
      id: 'showreel',
      title: 'Showreel',
      endTrigger: '=+140%',
      component: (props) => <Showreel {...props} />
    },
    {
      id: 'careers',
      title: 'Careers',
      endTrigger: 'bottom 50%',
      component: ({ id }) => <Careers id={id} />
    }
  ]

  const defaultSectionsState = sections.reduce((acc, { id }) => {
    acc[id] = false
    return acc
  }, {})

  const anchorMenuLinks = sections.map(({ id, title }) => ({ id, title }))

  const [sectionsState, setSectionsState] = useState(defaultSectionsState)
  const [fullScreenShowReel, setFullScreenShowReel] = useState(false)
  const [showFullMenu, setShowFullMenu] = useState(false)

  const handleOpenModal = () => {
    setFullScreenShowReel(true)
    videoRef.current.muted = false
  }

  const handleCloseModal = () => {
    setFullScreenShowReel(false)
    videoRef.current.muted = true
    videoRef.current.currentTime = 0
    videoRef.current.load()
  }

  const handleMenuAction = () => {
    setShowFullMenu((menuState) => !menuState)
  }

  useOutsideClick(videoRef, () => {
    handleCloseModal(false)
  })

  return (
    <Layout title="Advertising Services">
      <Header menuAction={handleMenuAction} />
      <Hero />
      <main tw="relative">
        {sections.map(({ id, endTrigger, component }) => (
          <React.Fragment key={id}>
            <ScrollTrigger
              trigger={`#${id}`}
              start="top 50%"
              end={endTrigger}
              onToggle={() =>
                setSectionsState((sectionsState) => ({
                  ...sectionsState,
                  [id]: !sectionsState[id]
                }))
              }
            >
              <Tween>
                <div>{component({ id, fullScreenShowReel, handleOpenModal })}</div>
              </Tween>
            </ScrollTrigger>
          </React.Fragment>
        ))}
        <AnchorMenu sectionsState={sectionsState} links={anchorMenuLinks} />
      </main>
      <Footer />
      <Menu show={showFullMenu} closeMenu={handleMenuAction} location={location} />
      <Modal closeModal={handleCloseModal} show={fullScreenShowReel}>
        <video css={videoStyles} playsInline autoPlay muted controls ref={videoRef}>
          <source type="video/mp4" src={videoSrc} />
        </video>
      </Modal>
    </Layout>
  )
}

export default IndexPage
