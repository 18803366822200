import React, { useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { colors, breakpoints } from '../../../config/design-system'
import { toRem, mediaQuery } from '../../styles'
import overlayVideo from '../../assets/home/hero_overlay.mp4'
import creatorsVideo from '../../assets/home/hero_creators.mp4'
import brandsVideo from '../../assets/home/hero_brands.mp4'

const StyledHeroLink = styled.a`
  ${tw`w-full flex justify-center items-center bg-dark-bg-color relative overflow-hidden xl:h-full`};
  ${({ active }) => (active ? tw` xl:w-3/5` : tw`xl:w-2/5`)}};
  height: 50%;
  transition: width 0.3s;
  strong {
    ${tw`font-title uppercase font-medium`};
    font-size: ${toRem(144)};
    transition: all 0.3s;
    @media (min-width: ${breakpoints.desktop}px) {
      font-size: ${toRem(375)};
      ${({ active }) => !active && tw`text-transparent font-light`};
      -webkit-text-stroke: 1px ${colors['light-text-color']};
   }
  }
`

const videoStyles = css`
  ${tw`absolute opacity-100 visible`};
  width: auto;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: unset;
  transition: opacity 0.15s, visibility 0.15s;
  ${mediaQuery(
    `
    height: auto;
    width: 100%;
    max-width: 100%;
  `,
    'phone'
  )}
  ${mediaQuery(
    `
    width: auto;
    height: 100%;
    max-width: unset;
  `,
    'desktop'
  )}
`

const activeVideoStyles = css`
  ${tw`xl:opacity-0 xl:invisible`};
`

const overlayVideoStyles = css`
  ${tw`hidden xl:block`};
`

const scrollDownContainerStyles = css`
  ${tw`flex justify-center items-center w-full absolute bottom-0`};
  height: 10%;
  background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 100) 100%);
`

const scrollDownLinkStyles = css`
  ${tw`font-thin`};
  &:before,
  &:after {
    content: '↓';
    display: inline-flex;
    margin: 0 0.5rem;
  }
`

const Video = ({ src, active, overlay }) => (
  <video
    css={[videoStyles, active && activeVideoStyles, overlay && overlayVideoStyles]}
    loop
    autoPlay
    playsInline
    muted
  >
    <source type="video/mp4" src={src} />
  </video>
)

const Hero = () => {
  const sliders = [
    {
      id: 'creators',
      active: true,
      video: creatorsVideo,
      href: '/creators',
      title: 'Creators'
    },
    {
      id: 'brands',
      active: false,
      video: brandsVideo,
      href: '/brands',
      title: 'Brands'
    }
  ]

  const defaultSlidersState = {
    creators: true,
    brands: false
  }

  const [slidersState, setSlidersState] = useState(defaultSlidersState)

  const handleOnMouseEnter = (id) => {
    setSlidersState((slidersState) =>
      Object.keys(slidersState).reduce((acc, key) => {
        acc[key] = key === id
        return acc
      }, {})
    )
  }

  return (
    <section
      tw="w-screen h-screen relative"
      css={css`
        max-width: 100%;
      `}
    >
      <div tw="w-full h-full flex flex-col xl:flex-row">
        {sliders.map(({ id, video: activeVideo, href, title }) => {
          const active = slidersState[id]
          return (
            <StyledHeroLink
              key={id}
              onMouseEnter={() => handleOnMouseEnter(id)}
              active={active}
              href={href}
            >
              <Video active={!active} src={activeVideo} />
              <Video active={active} src={overlayVideo} overlay />
              <strong tw="absolute">{title}</strong>
            </StyledHeroLink>
          )
        })}
      </div>
      <div css={scrollDownContainerStyles}>
        <a css={scrollDownLinkStyles} href="#showreel">
          Scroll Down
        </a>
      </div>
    </section>
  )
}

export { Hero }
